/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Loader, Select, Alert } from 'lec-ui';
import queryString from 'query-string';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import messages from './classManagementMessages';
import ClassGrid from './classGrid';
import SchoolVacation from './SchoolVacationContainer';

import * as classManagementActions from './classManagementActions';
import * as panelActions from '../panel/panelActions';

import * as configurationActions from '../configuration/configurationActions';

// import CardMonth from '../calendar/CardMonth';

import './ClassManagement.scss';

const ClassManagementContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    filters,
    history,
    isLoading,
    getCourses,
    courses,
    schedulePlan,
    getPlanCoursesHour,
    addHour,
    deleteHour,
    coursesHours,
    errorPlanCode,
    errorPlanInfo,
    user,
    schools,
    getSchools,
    saveSchoolDetails,
    courseHourSuccess,
    idPlanScheduled,
    // getScheduledLessonsDates,
    // scheduledLessonsDates,
    segmentFilterType,
  } = props;

  // function toDayDate(date) {
  //   return moment(date).startOf('day').toDate();
  // }

  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [componentLabel, setComponentLabel] = useState('');

  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);
  const [idPlan] = useState(params.planSaved);
  const [period] = useState(params.period);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);

  const [filterClass, setFilterClass] = useState([]);
  const [idCourse, setIdCourse] = useState(null);

  const [arrAllDays, setArrAllDays] = useState([]);
  const [addRow, setAddRow] = useState(false);
  const [auxSelectDay, setAuxSelectDay] = useState(false);

  const [column, setColumn] = useState(null);
  const [row, setRow] = useState(null);

  // const [currentDate, setCurrentDate] = useState(toDayDate());

  const [showSchoolVactionModal, setShowSchoolVactionModal] = useState(false);

  const [idUserSchool, setIdUserSchool] = useState(null);

  const [classNamesSaved, setClassNamesSaved] = useState([]);
  // const [scheduledSucess, setScheduledSucess] = useState(false);

  const [saveButtonDisable, setSaveButtonDisable] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setFilterSegment([]);
      setFilterComponent([]);
      setArrAllDays([]);
      await getFilterCatalogings();
      await getCourses({
        idSegment: idSegmentSelected || params.segment,
        idComponent: idComponentSelected || params.component,
      });
    })();

    const days = [...Array(6).keys()];
    for (let index = 0; index < days.length; index++) {
      days[index] = [];
      for (let index2 = 0; index2 < days.length; index2++) {
        days[index].push({ status: 0 });
      }
    }
    setIdSegmentSelected(params.segment);
    setIdComponentSelected(params.component);
    setArrAllDays(days);
    // setScheduledSucess(false);

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    setLoading(true);
    if (idCourse) {
      await getPlanCoursesHour({ idPlanSaved: params.planSaved, idCourse });
    }
    setLoading(false);
  }, [idCourse]);

  useEffect(async () => {
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
    }
  }, [idSegment, filterSegment]);

  useEffect(() => {
    if (idComponentSelected && filterComponent.length > 0) {
      const index = filterComponent.findIndex((item) => item.value === idComponentSelected);
      if (index !== -1) {
        setComponentLabel(filterComponent[index].label);
      }
    }
  }, [idComponentSelected, filterComponent]);

  useEffect(() => {
    if (addRow) {
      const days = arrAllDays.slice();
      const { length } = days;

      days[length] = [];
      for (let index = 0; index < 6; index++) {
        days[length].push({ status: 0 });
      }
      setArrAllDays(days);
      setAddRow(false);
    }
  }, [addRow]);

  useEffect(async () => {
    setLoading(true);
    if (auxSelectDay) {
      if (arrAllDays[row - 1][column].status === 1) {
        arrAllDays[row - 1][column].status = 0;
        await deleteHour({
          hour: row,
          idCourse,
          idPlanSaved: params.planSaved,
          weekDay: column + 2,
        });
      } else {
        arrAllDays[row - 1][column].status = 1;
        await addHour({
          hour: row,
          idCourse,
          idPlanSaved: params.planSaved,
          weekDay: column + 2,
        });
      }
      setAuxSelectDay(false);
      if (idCourse) {
        await getPlanCoursesHour({ idPlanSaved: params.planSaved, idCourse });
      }
      setLoading(false);
    }
  }, [auxSelectDay]);

  useEffect(() => {
    if (courses.length > 0) {
      const arr = [];

      courses.forEach((item) => {
        arr.push({
          value: item.id,
          label: item.name,
        });
      });
      setFilterClass(arr);
    }
  }, [courses]);

  useEffect(() => {
    if (coursesHours.length > 0 && courseHourSuccess) {
      let idCourseAux;
      let days = [];
      const hasHoursIndex = coursesHours.findIndex((el) => el.courseDaysHours.length > 0);

      if (!idCourse && hasHoursIndex !== -1) {
        idCourseAux = coursesHours[hasHoursIndex].idCourse;
      }

      const courseIndex = coursesHours.findIndex(
        (item) => item.idCourse === idCourse || idCourseAux,
      );

      days = [...Array(6).keys()];
      for (let index = 0; index < days.length; index++) {
        days[index] = [];
        for (let index2 = 0; index2 < days.length; index2++) {
          days[index].push({ status: 0 });
        }
      }

      if (coursesHours[courseIndex] && coursesHours[courseIndex].courseDaysHours.length > 0) {
        // days = arrAllDays.slice();
        const { length } = days;

        coursesHours[courseIndex].courseDaysHours.forEach((el) => {
          if (el.hour > length) {
            const diff = el.hour - length;
            for (let i = 0; i < diff; i++) {
              days[length + i] = [];
              for (let index = 0; index < 6; index++) {
                days[length + i].push({ status: 0 });
              }
            }
          }
        });

        coursesHours[courseIndex].courseDaysHours.forEach((el) => {
          days[el.hour - 1][el.weekDay - 2].status = 1;
        });
      }

      setArrAllDays(days);
    }
  }, [coursesHours]);

  useEffect(() => {
    if (errorPlanCode === 14) {
      setShowSchoolVactionModal(true);
    }
  }, [errorPlanCode]);

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    if (schools.length > 0) {
      setIdUserSchool(schools[0].idUserSchool);
    }
  }, [schools]);

  useEffect(() => {
    if (idPlanScheduled) {
      history.push(
        `/validation?idPlan=${params.idPlan}&planSaved=${idPlan || params.planSaved}&segment=${
          idSegmentSelected || params.segment
        }&component=${idComponentSelected || params.component}&period=${
          period || params.period
        }&scheduled=${idPlanScheduled || ''}&className=${classNamesSaved || ''}`,
      );
    }
    setLoading(false);
  }, [idPlanScheduled]);

  useEffect(() => {
    let finalStatus = true;

    arrAllDays.map((all) =>
      all.filter((item) => {
        if (finalStatus && item.status === 1) {
          finalStatus = false;
        }
      }),
    );
    setSaveButtonDisable(finalStatus);
  }, [arrAllDays]);

  // function handleSucess() {
  //   setScheduledSucess(true);
  //   setLoading(false);
  // }

  // useEffect(() => {
  //   const currentMonthNumber = moment(currentDate).month() + 1;
  //   const oneMonthNumberFromNow = moment(currentDate).add(1, 'month').month() + 1;
  //   const twoMonthsNumberFromNow = moment(currentDate).add(2, 'month').month() + 1;

  //   getScheduledLessonsDates({
  //     months: [currentMonthNumber, oneMonthNumberFromNow, twoMonthsNumberFromNow],
  //   });
  // }, [currentDate]);

  async function handleSavePlan() {
    if (idCourse && idPlan && period) {
      const newIdCourses = [];
      const coursesNames = [];
      setLoading(true);

      coursesHours.map((el) => {
        if (el.courseDaysHours.length > 0) {
          if (!newIdCourses.includes(el) && el.idCourse === idCourse) {
            newIdCourses.push(el.idCourse);
            coursesNames.push(el.courseName);
          }
        }
      });

      if (newIdCourses.length > 0) {
        setClassNamesSaved(coursesNames.join('|'));
        await schedulePlan({
          idPlanSaved: idPlan,
          idsCourses: newIdCourses,
          period,
        })
          // .then(() => {
          //   handleSucess();
          // })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }

  function handleBackToPlan() {
    history.goBack();
  }

  async function handleSelectClass(e) {
    setIdCourse(e);
    // await getPlanCoursesHour({ idPlanSaved: params.planSaved, idCourse: e });
  }

  function handleSelectDayClass(x, y) {
    setRow(x + 1);
    setColumn(y);
    setAuxSelectDay(true);
  }

  function handleAddRow() {
    setAddRow(true);
  }

  // function selectDate(date) {
  //   setCurrentDate(date);
  // }

  // function nextDate() {
  //   const nextCurrentDate = moment(currentDate).add(1, 'month').toDate();
  //   selectDate(nextCurrentDate);
  // }

  // function previousDate() {
  //   const nextCurrentDate = moment(currentDate).subtract(1, 'month').toDate();
  //   selectDate(nextCurrentDate);
  // }

  function closeSchoolVactionModal() {
    setShowSchoolVactionModal(false);
  }

  async function handleSubmitSchoolVacation(values) {
    await saveSchoolDetails(values);
    setShowSchoolVactionModal(false);
  }

  // const oneMonthFromNow = moment(currentDate).add(1, 'month').toDate();
  // const twoMonthsFromNow = moment(currentDate).add(2, 'month').toDate();

  return (
    <Fragment>
      <Row>
        <Loader show={isLoading || loading} />
        <Col xs={12} md={12} lg={12} xl={12}>
          <Row className="mb-2">
            <Col>
              <PanelFilters
                idSegment={idSegment}
                idComponent={idComponent}
                filterSegment={filterSegment}
                filterComponent={filterComponent}
                setIdSegment={(value) => setIdSegment(value)}
                setIdComponent={(value) => setIdComponent(value)}
                idSegmentSelected={idSegmentSelected || params.segment}
                idComponentSelected={idComponentSelected || params.component}
                disabled
              />
              <div className="cp-container">
                <div>
                  <h3 className="cp-title">{intl.formatMessage(messages.selectClass)}</h3>
                </div>

                <div className="d-flex">
                  <button type="button" onClick={() => handleBackToPlan()} className="btn-back">
                    {intl.formatMessage(messages.back)}
                  </button>

                  <Button
                    onClick={() => handleSavePlan()}
                    className="btn"
                    disabled={
                      // !(
                      //   idCourse && arrAllDays.map((all) => all.filter((item) => item.status === 1))
                      // )
                      saveButtonDisable
                    }
                  >
                    {intl.formatMessage(messages.save)}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {errorPlanCode &&
            (errorPlanCode === 14 ? (
              <SchoolVacation
                closeSchoolVactionModal={closeSchoolVactionModal}
                showModal={showSchoolVactionModal}
                errorMessage={intl.formatMessage(messages.errorCode14)}
                onSubmit={(e) => handleSubmitSchoolVacation(e)}
                user={user}
                idUserSchool={idUserSchool}
              />
            ) : (
              <Row>
                <Col>
                  <Alert type="danger" className="text-left">
                    {intl.formatMessage(
                      messages[`errorCode${errorPlanCode}`] || messages.errorCode500,
                      {
                        planLessonsCount: errorPlanInfo ? errorPlanInfo.planLessonsCount : 0,
                        teacherLessonsCount: errorPlanInfo ? errorPlanInfo.teacherLessonsCount : 0,
                        courseName: errorPlanInfo ? errorPlanInfo.courseName : '',
                      },
                    )}
                  </Alert>
                </Col>
              </Row>
            ))}
          <Row>
            <Col className="d-flex select-class align-items-center">
              {/* <b>{intl.formatMessage(messages.selectClass)}</b> */}
              <Select
                className="mt-0"
                placeholder={intl.formatMessage(messages.class)}
                onChange={(e) => handleSelectClass(e.target.value)}
                options={filterClass}
                value={idCourse || ''}
                nullable="true"
              />
            </Col>
          </Row>

          {idCourse && (
            <Row className="mt-5">
              <Col>
                <div className="d-flex align-items-center">
                  {intl.formatMessage(messages.selectDaysAndClasses)} &nbsp;<b>{componentLabel}</b>:
                </div>

                <ClassGrid
                  arrAllDays={arrAllDays}
                  handleSelectDayClass={(x, y) => handleSelectDayClass(x, y)}
                  handleAddRow={() => handleAddRow()}
                />
              </Col>
            </Row>
          )}
        </Col>
        {/* <Col xs={6} md={6} lg={3} xl={3} className="calendar-container">
          <button type="button" className="calendar-navButton" onClick={() => previousDate()}>
            <i className="fa fa-angle-up" />
          </button>
          <CardMonth
            month={currentDate.getMonth() + 1}
            year={currentDate.getFullYear()}
            onClickDate={selectDate}
            appointments={scheduledLessonsDates}
          />
          <CardMonth
            month={oneMonthFromNow.getMonth() + 1}
            year={oneMonthFromNow.getFullYear()}
            onClickDate={() => selectDate()}
            appointments={scheduledLessonsDates}
          />
          <CardMonth
            month={twoMonthsFromNow.getMonth() + 1}
            year={twoMonthsFromNow.getFullYear()}
            onClickDate={() => selectDate()}
            appointments={scheduledLessonsDates}
          />

          <button type="button" className="calendar-navButton" onClick={() => nextDate()}>
            <i className="fa fa-angle-down" />
          </button>
        </Col> */}
      </Row>
    </Fragment>
  );
};

ClassManagementContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  errorPlanInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  errorPlanCode: PropTypes.number,
  getFilterCatalogings: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getCourses: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.any).isRequired,
  schedulePlan: PropTypes.func.isRequired,
  getPlanCoursesHour: PropTypes.func.isRequired,
  addHour: PropTypes.func.isRequired,
  deleteHour: PropTypes.func.isRequired,
  coursesHours: PropTypes.arrayOf(PropTypes.any).isRequired,
  user: PropTypes.object.isRequired,
  getSchools: PropTypes.func.isRequired,
  schools: PropTypes.arrayOf(PropTypes.any).isRequired,
  saveSchoolDetails: PropTypes.func.isRequired,
  courseHourSuccess: PropTypes.bool,
  idPlanScheduled: PropTypes.number,
  // getScheduledLessonsDates: PropTypes.func.isRequired,
  // scheduledLessonsDates: PropTypes.arrayOf(PropTypes.any),
  segmentFilterType: PropTypes.bool.isRequired,
};

ClassManagementContainer.defaultProps = {
  intl: [],
  errorPlanCode: null,
  courseHourSuccess: false,
  idPlanScheduled: null,
  // scheduledLessonsDates: [],
};

const mapStateToProps = ({ classManagement, panel, app, configuration }) => ({
  ...classManagement,
  ...panel,
  user: app.user,
  schools: configuration.schools,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...classManagementActions, ...panelActions, ...configurationActions },
      {
        include: [
          'getFilterCatalogings',
          'getCourses',
          'schedulePlan',
          'getPlanCoursesHour',
          'addHour',
          'deleteHour',
          'getSchools',
          'saveSchoolDetails',
          // 'getScheduledLessonsDates',
        ],
      },
    ),
  ),
)(ClassManagementContainer);
