/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { Row, Col } from 'react-bootstrap';

import messages from './myPlansMessages';
import './MyPlanScheduledCourseCard.scss';

import { Avatar } from 'lec-ui';
import { getUserAvatarUrl } from 'app/appHelpers';

import placeholderSrc from 'app/images/placeholder-avatar.svg';

const MyPlanScheduledCourseCard = (props) => {
  const { intl, plan, scheduledPlan, showScheduledPlanInfo, deleteScheduledPlan } = props;

  return (
    <div className="myPlanScheduledCourseCard">
      <div className="planScheduledIdLabel">ID #{scheduledPlan.idPlanScheduled}</div>
      <Col xs="1">
        <b>{scheduledPlan.courseName}</b>
      </Col>
      <Col className="horizontal-column">
        {scheduledPlan.students.map((el) => (
          <Tooltip
            className="lessonsTooltip"
            position="top"
            theme="light"
            html={el.fullname && <div className="description">{el.fullname}</div>}
          >
            <Avatar
              src={getUserAvatarUrl(el.photo)}
              placeholderSrc={placeholderSrc}
              alt=""
              size={40}
            />
          </Tooltip>
        ))}
      </Col>
      <Col xs={3}>
        <div style={{ marginBottom: '5px' }}>
          <b>{intl.formatMessage(messages.updatedBy)}:</b> {plan.username}
        </div>
        <div>
          <b>{intl.formatMessage(messages.updatedAt)}:</b>{' '}
          {moment(scheduledPlan.updated ? scheduledPlan.updated : scheduledPlan.added).format(
            'DD/MM/YYYY',
          )}
        </div>
      </Col>
      <Col xs={3}>
        <div>
          {intl.formatMessage(messages.lessons)}: {scheduledPlan.lessonsCount}
        </div>
        {scheduledPlan.status && (
          <div
            className={`comments ${scheduledPlan.status === '1' ? 'approved' : 'rejected'}`}
            onClick={() => props.handleOpenComments(scheduledPlan.comments)}
          >
            <i className="icon pe-7s-mail" />
          </div>
        )}
      </Col>
      <Col xs={1} style={{ marginRight: '20px' }}>
        <button
          type="button"
          className="iconCard"
          onClick={() =>
            showScheduledPlanInfo(
              plan.id,
              plan.idParent,
              plan.idCataloging,
              scheduledPlan.period,
              scheduledPlan.idPlanScheduled,
            )
          }
        >
          <i className="icon pe-7s-search" />

          <div className="labelButton">{intl.formatMessage(messages.seePlanning)}</div>
        </button>
        <button
          type="button"
          className="iconCard"
          onClick={() => deleteScheduledPlan(scheduledPlan.idPlanScheduled)}
        >
          <i className="icon pe-7s-close-circle" />

          <div className="labelButton">{intl.formatMessage(messages.removePlanning)}</div>
        </button>
      </Col>
    </div>
  );
};

MyPlanScheduledCourseCard.propTypes = {
  intl: intlShape.isRequired,
  plan: PropTypes.any.isRequired,
  scheduledPlan: PropTypes.any.isRequired,
  showScheduledPlanInfo: PropTypes.func.isRequired,
  deleteScheduledPlan: PropTypes.func.isRequired,
};

export default compose(injectIntl)(MyPlanScheduledCourseCard);
