import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'myPlans.title',
    defaultMessage: 'Plans created',
  },
  planned: {
    id: 'myPlans.planned',
    defaultMessage: 'Planned',
  },
  underConstruction: {
    id: 'myPlans.underConstruction',
    defaultMessage: 'Under construction',
  },
  updatedAt: {
    id: 'myPlans.updatedAt',
    defaultMessage: 'Updated at',
  },
  updatedBy: {
    id: 'myPlans.updatedBy',
    defaultMessage: 'Updated by',
  },
  noPlansFound: {
    id: 'myPlans.noPlansFound',
    defaultMessage: 'No plans found',
  },
  removePlan: {
    id: 'myPlans.removePlan',
    defaultMessage: 'Remove plan',
  },
  removePlanText: {
    id: 'myPlans.removePlanText',
    defaultMessage: 'Are you sure you want to delete this annual plan?',
  },
  removePlanTextScheduled: {
    id: 'myPlans.removePlanTextScheduled',
    defaultMessage:
      'Are you sure you want to delete this annual plan? This plan has already been scheduled for class',
  },
  cancel: {
    id: 'myPlans.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'myPlans.confirm',
    defaultMessage: 'Confirm',
  },
  close: {
    id: 'myPlans.close',
    defaultMessage: 'Close',
  },
  genericError: {
    id: 'myPlans.genericError',
    defaultMessage: 'An error occurred, please try again later.',
  },
  deletedWithSuccess: {
    id: 'myPlans.deletedWithSuccess',
    defaultMessage: 'Plan deleted with success',
  },
  lessons: {
    id: 'myPlans.lessons',
    defaultMessage: 'Lessons',
  },
  deletedScheduledPlanWithSuccess: {
    id: 'myPlans.deletedScheduledPlanWithSuccess',
    defaultMessage: 'Scheduled plan deleted with success',
  },
  removeScheduledPlan: {
    id: 'myPlans.removeScheduledPlan',
    defaultMessage: 'Remove scheduled plan',
  },
  removeScheduledPlanText: {
    id: 'myPlans.removeScheduledPlanText',
    defaultMessage: 'Are you sure you want to delete this scheduled plan?',
  },
  scheduledForCourses: {
    id: 'myPlans.scheduledForCourses',
    defaultMessage: 'Scheduled for courses:',
  },
  seePlan: {
    id: 'myPlans.seePlan',
    defaultMessage: 'See plan',
  },
  seePlanning: {
    id: 'myPlans.seePlanning',
    defaultMessage: 'See planning',
  },
  removePlanning: {
    id: 'myPlans.removePlanning',
    defaultMessage: 'Remove planning',
  },
  comment: {
    id: 'myPlans.comment',
    defaultMessage: 'Comment',
  },
});
